@import url("react-toastify/dist/ReactToastify.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  scroll-behavior: smooth;
}

input[type="text"]:focus {
  box-shadow: 0 0 0 transparent;
}

.cursor {
  opacity: 1;
  display: inline-block;
  width: 18px;
  border-bottom: 0.1em solid currentColor;
  animation: blink 0.5s steps(1) infinite;
}
@media (max-width: 640px) {
  .cursor {
    width: 10px;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
